/* You can add global styles to this file, and also import other style files */
@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.snow.css";
.min-height-0 {
  min-height: 0 !important;
}

.d-contents {
  display: contents !important;
}

.minitext {
  font-size: 12px;
  color: gray;
}

html,
body {
  height: 100%;
  width: 100%;
}

body {
  margin: 0;
  font-family: "Open Sans", sans-serif !important;
}

:host {
  --dial-background-color: var(--nav-color) !important;
  --clock-hand-color: var(--nav-color) !important;
  --button-color: var(--nav-color)!important;
}

.clickable {
  cursor: pointer !important;
}

.nav-tabs {
  border-bottom: 0 !important;
}

.nav-link {
  color: var(--nav-color) !important;
  border-bottom: 1px solid;
  border-color: rgba(227, 227, 228, 0.5) !important;
}
.nav-link:hover {
  background-color: rgba(227, 227, 228, 0.3) !important;
}
.nav-link.active {
  background-color: rgba(227, 227, 228, 0.3) !important;
  border-bottom: 0 !important;
}

.mat-mdc-chip-listbox[aria-orientation=vertical] .mdc-evolution-chip-set__chips {
  display: flex;
  flex-direction: column;
}

.mat-mdc-dialog-container .mdc-dialog__surface {
  overflow-x: hidden !important;
}

.w-fit-content {
  width: fit-content;
}

.mat-mdc-chip {
  background-color: white !important;
  border: 1px solid rgb(227, 227, 228);
}
.mat-mdc-chip.active {
  background-color: #618baa !important;
}
.mat-mdc-chip.active .mat-mdc-chip-action-label {
  color: white !important;
}
.mat-mdc-chip .mdc-evolution-chip__cell {
  justify-content: center;
}
.mat-mdc-chip:not(.disabled) .mdc-evolution-chip__cell > * {
  cursor: pointer;
}

.mat-mdc-menu-content,
.mat-mdc-select-panel {
  padding: 0 !important;
}

.mat-mdc-option {
  padding: 0 0.5rem !important;
}

.mat-mdc-select-panel-above {
  width: fit-content !important;
}

.mat-mdc-form-field {
  width: 100%;
}

.leader-line {
  z-index: 1000;
  cursor: pointer;
}

body .border-gray {
  border-color: #d5d5d5 !important;
}
body .border-dotted {
  border-style: dotted !important;
}
body .border-dashed {
  border-style: dashed !important;
}
body .border-width-1 {
  border-width: 1px !important;
}
body .border-width-2 {
  border-width: 2px !important;
}
body .border-width-3 {
  border-width: 3px !important;
}
body .border-width-4 {
  border-width: 4px !important;
}
body .border-width-5 {
  border-width: 5px !important;
}

.mat-mdc-dialog-container .mdc-dialog__surface {
  overflow: hidden;
}

.mat-mdc-table .mdc-data-table__row:not(.example-detail-row) {
  height: 32px;
}
.mat-mdc-table .mdc-data-table__row:not(.example-detail-row) .mat-mdc-icon-button {
  height: 32px;
  width: 32px;
  padding: 4px;
}

.mat-mdc-select-disabled .mat-mdc-select-value {
  color: black !important;
}

.mat-mdc-select-disabled .mat-mdc-select-arrow-wrapper {
  display: none;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: white !important;
}

.mat-mdc-form-field-subscript-wrapper {
  display: none !important;
}

.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full, .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background-color: var(--nav-color) !important;
}

.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after, .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: var(--nav-contrast) !important;
}

.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:checked + .mdc-radio__background .mdc-radio__outer-circle, .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:checked + .mdc-radio__background .mdc-radio__inner-circle {
  border-color: var(--nav-color) !important;
}

.mat-mdc-checkbox.mat-accent {
  --mdc-checkbox-selected-checkmark-color: var(--nav-contrast) !important;
  --mdc-checkbox-selected-focus-icon-color: var(--nav-color) !important;
  --mdc-checkbox-selected-hover-icon-color: var(--nav-color) !important;
  --mdc-checkbox-selected-icon-color: var(--nav-color) !important;
  --mdc-checkbox-selected-pressed-icon-color: var(--nav-color) !important;
}

.mat-mdc-form-field-underline {
  /*change color of underline*/
  background-color: var(--nav-color) !important;
}

.mat-mdc-form-field-ripple {
  /*change color of underline when focused*/
  background-color: var(--nav-color) !important;
}

mat-datepicker-toggle {
  color: var(--nav-color) !important;
}

.mat-calendar-body-selected {
  background-color: var(--nav-color) !important;
  color: var(--nav-contrast) !important;
}

.mat-mdc-snack-bar-container {
  --mat-mdc-snack-bar-button-color: #ffffff !important;
  --mdc-snackbar-supporting-text-color: #ffffff;
}
.mat-mdc-snack-bar-container.app-notification-error {
  --mdc-snackbar-container-color: #ff6b50;
}
.mat-mdc-snack-bar-container.app-notification-success {
  --mdc-snackbar-container-color: #7cc97e;
}

.timepicker-overlay {
  z-index: 1100 !important;
}

.timepicker-backdrop-overlay {
  z-index: 1100 !important;
}

.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  z-index: 1000 !important;
}

mat-mdc-form-field .mat-focused mdc-notched-outline__leading,
mat-mdc-form-field .mat-focused mdc-notched-outline__notch,
mat-mdc-form-field .mat-focused mdc-notched-outline__trailing {
  border-left-color: var(--nav-color) !important;
  border-right-color: var(--nav-color) !important;
  border-top-color: var(--nav-color) !important;
  border-bottom-color: var(--nav-color) !important;
}

.mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--nav-color) !important;
}

@media only screen and (max-width: 680px) {
  html, body {
    overflow-x: hidden;
  }
  body {
    position: absolute;
    width: 100vw;
  }
  .cdk-overlay-pane {
    max-width: 100vw !important;
    overflow-y: scroll !important;
  }
  .mat-expansion-panel-body {
    padding: 0px !important;
  }
  .timePicker {
    direction: ltr;
    position: absolute;
    left: 0 !important;
  }
  .mat-mdc-tab-link .mdc-tab__text-label, .mdc-list-item__primary-text {
    font-size: 0.7rem !important;
  }
  .mat-mdc-floating-label {
    line-height: 1.1 !important;
  }
}